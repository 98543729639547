import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

// eslint-disable-next-line no-unused-vars
const Logo = forwardRef(({ disabledLink = false }, ref) => {
  const logo = (
    <Box
      component="img"
      sx={{
        height: 58,
        width: 180,
      }}
      alt="ausrehab"
      src="/assets/ausrehab.png"
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
