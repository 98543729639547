import { useEffect } from 'react';
import { FormControl, FormLabel, NativeSelect, Skeleton } from '@mui/material';
import { getIn } from 'formik';

import { useGetSuppliersQuery } from '../../../slices/api';
import Request from '../../../utils/request';
import Supplier from '../../../utils/supplier';

const Suppliers = ({ formik, showFormLabel = false }) => {
  const { setFieldValue } = formik;

  const { data: suppliers } = useGetSuppliersQuery(
    {
      location: Request.normalizeAddress(
        formik.values.candidate.addrLine,
        formik.values.candidate.addrSuburb,
        formik.values.candidate.addrState,
        formik.values.candidate.addrPostcode
      ),
      testIds: Supplier.formatTestsPayload(formik.values.tests),
    },
    {
      skip: !Request.isAddressValid(
        formik.values.candidate.addrLine,
        formik.values.candidate.addrSuburb,
        formik.values.candidate.addrState,
        formik.values.candidate.addrPostcode
      ),
    }
  );

  const handleChange = e => formik.setFieldValue('supplierId', Number(e.target.value));

  useEffect(() => {
    setFieldValue('supplierId', suppliers ? suppliers[0].id : '');
  }, [suppliers, setFieldValue]);

  return (
    <>
      {!suppliers ? (
        <Skeleton variant="rectangular" width="30%" />
      ) : (
        <FormControl
          margin="normal"
          component="fieldset"
          variant="standard"
          error={Boolean(getIn(formik.touched, 'supplierId') && getIn(formik.errors, 'supplierId'))}
        >
          {showFormLabel ? <FormLabel component="legend">Suppliers</FormLabel> : null}
          <NativeSelect
            defaultValue={formik.values.supplierId || suppliers[0].id}
            inputProps={{
              name: 'supplierId',
              id: 'uncontrolled-native',
              onChange: handleChange,
            }}
          >
            {suppliers.map(supplier => (
              <option key={supplier.id} value={supplier.id}>
                {supplier.name} {Request.formatSupplierDistance(supplier.distanceInMeters)}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      )}
    </>
  );
};

export default Suppliers;
