import * as React from 'react';
import { CardContent, CardHeader, Typography, Divider, Grid } from '@mui/material';
import { sentenceCase } from 'change-case';

import Label from '../../../components/label';
import { fCurrency } from '../../../utils/formatNumber';

const style = {
  divider: {
    borderBottomWidth: 1.5,
  },
};

const RequestDetailCardContent = ({ request }) => {
  const { id, candidate, tests, supplier, status } = request;

  const renderStatus = Boolean(status);

  return (
    <>
      {id ? (
        <>
          <CardHeader title="ID" />
          <Divider sx={style.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {id}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      ) : null}
      {renderStatus ? (
        <>
          <CardHeader title="State" />
          <Divider sx={style.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <Chip label={status} color="primary" variant="filled" sx={{ borderRadius: 5 }} /> */}
                <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
              </Grid>
            </Grid>
          </CardContent>
        </>
      ) : null}
      <CardHeader title="Personal Information" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              First Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.firstname}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Last Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.lastname}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader title="Contact" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Phone
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.phone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Email
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader title="Address" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.addrLine}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Suburb
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.addrSuburb}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Postcode
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.addrPostcode}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate.addrState}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader title="Test" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {tests.map(test => {
              return (
                <Typography key={test.id} variant="body1" gutterBottom>
                  {test.name} <span style={{ margin: 8, padding: 6 }}>{fCurrency(test.price)}</span>
                </Typography>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Supplier
            </Typography>
            <Typography variant="body1" gutterBottom>
              {supplier.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader title="Employer" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" gutterBottom>
              {candidate.employer}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default RequestDetailCardContent;
