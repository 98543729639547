import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import au from 'date-fns/locale/en-AU';
import { useAuth0 } from '@auth0/auth0-react';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';

import tokenManager from './utils/tokenManager';
import { useSyncNewUserMutation } from './slices/api';

// ----------------------------------------------------------------------

export default function App() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [syncNewUser, { isLoading }] = useSyncNewUserMutation();

  tokenManager.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (user?.logins_count < 3) {
      syncNewUser();
    }
  }, [syncNewUser, user?.logins_count]);

  if (isLoading) {
    return <div>loading....</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={au}>
      <HelmetProvider>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </HelmetProvider>
    </LocalizationProvider>
  );
}
