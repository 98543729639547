import * as Yup from 'yup';

const shape = {
  id: Yup.number().required().positive().integer().default(0),
  firstname: Yup.string().default('').required('Required'),
  lastname: Yup.string().default('').required('Required'),
  email: Yup.string().email('Invalid email').default(''),
  roleId: Yup.number().required('Required').positive().integer().default(0),
  showSupplierDropDown: Yup.boolean().default(false),
  supplierId: Yup.number().when('showSupplierDropDown', {
    is: true,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  company: Yup.string().default('').required('Required'),
};

const User = {
  schema: Yup.object()
    .shape(shape)
    .transform((value) => {
      // infer roleId from roles field
      if ('roles' in value) {
        const res = {};

        Object.keys(shape).forEach((field) => {
          if (field === 'showSupplierDropDown') {
            res[field] = !!value.supplierId;
          } else {
            res[field] = field === 'roleId' ? value.roles[0].id : value[field];
          }
        });

        return res;
      }

      return value;
    }),
  normalizeForUpdate: (user) => {
    return {
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      supplierId: user.supplierId,
      roles: [
        {
          id: user.roleId,
        },
      ],
    };
  },
};

export default User;
