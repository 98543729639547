import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  Skeleton,
  Stack,
} from '@mui/material';

import Request from '../../../utils/request';
import { useGetTestsQuery } from '../../../slices/api';

const Tests = ({ formik, showFormLabel = false }) => {
  const { data: tests } = useGetTestsQuery();

  const ifTestChecked = Request.ifMarkTestChecked(formik.values.tests);

  const onTestChange = e => {
    const newValue = e.target.checked
      ? [
          ...formik.values.tests,
          {
            id: e.target.name,
          },
        ]
      : formik.values.tests.filter(test => Number(test.id) !== Number(e.target.name));

    formik.setFieldValue('tests', newValue);
  };

  const ifError = formik.touched.tests && formik.errors.tests;

  return (
    <>
      {!tests ? (
        <Stack sx={{ width: '40%' }} spacing={2}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Stack>
      ) : (
        <FormControl margin="normal" component="fieldset" variant="standard" error={ifError}>
          {showFormLabel ? <FormLabel component="legend">Tests</FormLabel> : null}
          {ifError ? <FormHelperText error>{formik.errors.tests}</FormHelperText> : null}
          <FormGroup>
            {tests.map(test => (
              <FormControlLabel
                key={test.id}
                control={<Checkbox name={test.id.toString()} checked={ifTestChecked(test)} onChange={onTestChange} />}
                label={test.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export default Tests;
