import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik, getIn } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, CircularProgress, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { state } from '../utils/config';
import { Suppliers, Tests, Status } from '../sections/@dashboard/request';
import Request from '../utils/request';
import { useGetRequestQuery, useUpdateRequestMutation } from '../slices/api';

export default function EditRequest({ isUser, isAdmin, isSupplier }) {
  const { reqId } = useParams();
  const navigate = useNavigate();
  const [updateRequest, { isSuccess, isError, isLoading }] = useUpdateRequestMutation();

  const { data: request } = useGetRequestQuery(reqId);

  const formik = useFormik({
    initialValues: useMemo(() => {
      if (request) {
        return Request.fetchSchema().cast(
          {
            ...request,
            supplierId: request.supplier.id,
          },
          {
            stripUnknown: true,
          }
        );
      }

      return Request.fetchSchema().cast();
    }, [request]),
    enableReinitialize: true,
    validationSchema: Request.fetchSchema(),
    onSubmit: values => {
      updateRequest(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/dashboard/requests');
    }
    if (isError) {
      formik.setSubmitting(false);
    }
  }, [formik, isSuccess, isError, navigate]);

  if (!request) {
    return <CircularProgress sx={{ m: 1 }} />;
  }

  return (
    <>
      <Helmet>
        <title> Edit Request - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Grid container justifyContent="start">
        <Grid item md={6}>
          <Typography variant="h4" gutterBottom>
            Edit Request
          </Typography>
          <Card md={{ m: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="firstname"
                      name="candidate.firstname"
                      label="First Name"
                      variant="standard"
                      value={formik.values.candidate.firstname}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname')
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="lastname"
                      name="candidate.lastname"
                      label="Last Name"
                      variant="standard"
                      value={formik.values.candidate.lastname}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname')
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="phone"
                      name="candidate.phone"
                      label="Phone"
                      variant="standard"
                      inputProps={{ inputMode: 'numeric', pattern: '^0[45]\\d{8}$', maxLength: 10 }}
                      value={formik.values.candidate.phone}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone')
                      )}
                      helperText={getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone')}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="email"
                      name="candidate.email"
                      label="Email"
                      variant="standard"
                      type="email"
                      value={formik.values.candidate.email}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email')
                      )}
                      helperText={getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email')}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="addrline"
                      name="candidate.addrLine"
                      label="Address"
                      variant="standard"
                      value={formik.values.candidate.addrLine}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine')
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="addrsuburb"
                      name="candidate.addrSuburb"
                      label="Suburb"
                      variant="standard"
                      value={formik.values.candidate.addrSuburb}
                      // onBlur={onSuburbBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb')
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="addrpostcode"
                      name="candidate.addrPostcode"
                      label="Postcode"
                      variant="standard"
                      inputProps={{ inputMode: 'numeric', maxLength: 4 }}
                      value={formik.values.candidate.addrPostcode}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.addrPostcode') &&
                          getIn(formik.errors, 'candidate.addrPostcode')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.addrPostcode') &&
                        getIn(formik.errors, 'candidate.addrPostcode')
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      id="addrstate"
                      name="candidate.addrState"
                      label="State"
                      variant="standard"
                      value={formik.values.candidate.addrState}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState')
                      }
                    >
                      {state.map(s => (
                        <MenuItem key={s.name} value={s.label}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={12}>
                    <Tests formik={formik} showFormLabel />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="employer"
                      name="candidate.employer"
                      label="Employer"
                      variant="standard"
                      value={formik.values.candidate.employer}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer')
                      )}
                      helperText={
                        getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer')
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Suppliers formik={formik} showFormLabel />
                  </Grid>
                  <Grid item md={12}>
                    <Status formik={formik} showFormLabel isAdmin={isAdmin} isUser={isUser} isSupplier={isSupplier} />
                  </Grid>
                  <Grid item md={6}>
                    <Grid container justifyContent="start">
                      <Grid item md={6}>
                        <LoadingButton loading={isLoading} variant="outlined" color="primary" type="submit">
                          <span>Update</span>
                        </LoadingButton>
                      </Grid>
                      <Grid item md={6}>
                        <Button color="error" variant="outlined" onClick={() => navigate('/dashboard/requests')}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
