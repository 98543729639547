import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { Button, Card, CardHeader, Paper, Stack, LinearProgress } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { useGetRequestQuery, usePayRequestMutation } from '../slices/api';
import DetailCardContent from '../sections/@dashboard/request/DetailCardContent';
import RequestReports from './RequestReports';
import Request from '../utils/request';

const RequestView = ({ isUser, backwardRoute }) => {
  const { reqId } = useParams();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [skip, setSkip] = React.useState(true);
  const [payRequest, { isSuccess, isLoading, data }] = usePayRequestMutation();
  const { data: reqData, isLoading: isGetRequestLoading } = useGetRequestQuery(reqId, {
    skip,
  });

  useEffect(() => {
    if (isSuccess && data) {
      window.location = data.url;
    }
  }, [isSuccess, data]);

  useEffect(() => {
    let timer;
    if (searchParam.get('from') === 'payment_success') {
      timer = setTimeout(() => {
        setSkip(false);
      }, 5000);
    } else {
      setSkip(false);
    }

    return () => clearTimeout(timer);
  }, [searchParam]);

  if (isGetRequestLoading || !reqData) {
    return <LinearProgress />;
  }

  const request = Request.cast(reqData);
  const { tests } = request;

  const onPayRequest = () => {
    payRequest({
      requestId: request.id,
      items: tests.map((test) => ({
        id: test.id,
      })),
    });
  };

  return (
    <>
      <Helmet>
        <title> View Request - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Paper elevation={6}>
        <Card>
          <CardHeader
            action={
              <Stack spacing={2} direction="row">
                {request.isReadyForPayment ? (
                  <LoadingButton
                    loading={isLoading}
                    variant="outlined"
                    color="primary"
                    onClick={onPayRequest}
                    disabled={isSuccess}
                  >
                    <span>Pay Now</span>
                  </LoadingButton>
                ) : null}
                <Button variant="outlined" color="error" onClick={() => navigate(backwardRoute)}>
                  Back
                </Button>
              </Stack>
            }
          />
          <DetailCardContent request={request} />
          <RequestReports request={request} isUser={isUser} />
        </Card>
      </Paper>
    </>
  );
};

export default RequestView;
