import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useFormik, setNestedObjectValues } from 'formik';
import {
  Stepper,
  Container,
  Step,
  StepLabel,
  Typography,
  Card,
  CardContent,
  Grid,
  FormHelperText,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { PersonalInfo, Address, Tests, Suppliers, Review } from '../sections/@dashboard/request';
import Request from '../utils/request';
import { useAddRequestMutation, usePayRequestMutation } from '../slices/api';

const steps = [
  {
    name: 'Personal/Contact Info',
    schema: 'CANDIDATE_PERSONAL_INFO_CONTACT',
  },
  {
    name: 'Address',
    schema: 'CANDIDATE_ADDRESS',
  },
  {
    name: 'Test',
    schema: 'TESTS',
  },
  {
    name: 'Supplier',
    schema: 'SUPPLIER',
  },
  {
    name: 'Review',
    schema: null,
  },
];

const NewRequest = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { schema: schemaName } = steps[activeStep];
  const [
    addRequest,
    {
      data: addRequestResponse,
      isSuccess: isAddRequestSuccess,
      isError: isAddRequestError,
      isLoading: isAddRequestLoading,
    },
  ] = useAddRequestMutation();
  const [
    payRequest,
    {
      isSuccess: isPayRequestSuccess,
      isLoading: isPayRequestLoading,
      data: payRequestResponse,
      error: payRequestError,
    },
  ] = usePayRequestMutation();

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const formik = useFormik({
    initialValues: Request.fetchSchema().cast(),
    validationSchema: Request.fetchSchema(schemaName),
    onSubmit: values => {
      addRequest(values);
    },
  });

  const onNextClick = async () => {
    const validationErrors = await formik.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true));
    } else {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    if (isAddRequestError) {
      formik.setSubmitting(false);
    }

    if (isAddRequestSuccess && !isPayRequestLoading && !payRequestError) {
      const { id, tests } = addRequestResponse;

      payRequest({
        requestId: id,
        items: tests.map(test => ({
          id: test.id,
        })),
      });
    }

    if (isPayRequestSuccess && payRequestResponse) {
      window.location = payRequestResponse.url;
    }
  }, [
    isAddRequestError,
    isAddRequestSuccess,
    isPayRequestSuccess,
    isPayRequestLoading,
    addRequestResponse,
    payRequest,
    payRequestResponse,
    payRequestError,
    formik,
  ]);

  const formContent = step => {
    switch (step) {
      case 0:
        return <PersonalInfo formik={formik} />;
      case 1:
        return <Address formik={formik} />;
      case 2:
        return <Tests formik={formik} />;
      case 3:
        return <Suppliers formik={formik} />;
      case 4:
        return <Review formik={formik} />;
      default:
        return <div>404: Not Found</div>;
    }
  };

  return (
    <>
      <Helmet>
        <title> New Request - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Container sx={{ bgcolor: 'rgba(145, 158, 171, 0.12)', paddingY: 3 }} maxWidth="md">
        <Typography variant="h4" align="center">
          New Request
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Card sx={{ marginTop: 2 }}>
            <CardContent sx={{ p: 5 }}>
              <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{step.name}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Grid container sx={{ my: 2 }}>
                <Grid item md={12}>
                  {formContent(activeStep)}
                </Grid>
                {formik.errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{formik.errors.submit}</FormHelperText>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4}>
                <Grid item>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  {activeStep === steps.length - 1 ? (
                    <LoadingButton
                      loading={isAddRequestLoading || isPayRequestLoading}
                      variant="outlined"
                      color="primary"
                      type="submit"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  ) : (
                    <Button variant="outlined" color="primary" onClick={onNextClick}>
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
};

export default NewRequest;
